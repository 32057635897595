import React, { useState, useEffect, Suspense } from 'react';
import './App.css';
import axios from 'axios';
import LearningPlans from './components/LearningPlans';
import { useAppContext } from './contexts/AppContext';
import { Spinner } from 'react-bootstrap';

function App() {
 
  const { currentUser, setCurrentUser, token, setToken } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  const authorizeClient = async () => {

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/auth${window.location.search}`, { withCredentials: true });
      setToken(response.data.access_token);
      axios.defaults.headers.common['Authorization'] = response.data.access_token;

    } catch (error) {
      console.log(`auth error: ${error}`)
    }
  }

  const getUserInfo = async (query) => {
    //101968 -> power user
    //13533 -> standard user
    let params = (new URL(document.location)).searchParams;
    let currentUser = params.get('user_id');
    const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/user/${currentUser}${window.location.search}`, { withCredentials: true }); //LIVE
    // const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/user/13533`, { withCredentials: true }); //DEV
    const { user_id, username, first_name, last_name } = response.data.user_data;
    let activeTrainingRequirement = response.data.additional_fields.find(field => field.id == process.env.REACT_APP_TRAINING_FIELD_ID)
    const userObj = { user_id, username, first_name, last_name, "activeTrainingRequirement": activeTrainingRequirement?.value }

    //check if power user
    try {
      const poweruserResp = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/poweruser/${currentUser}${window.location.search}`, { withCredentials: true }); //LIVE
      // const poweruserResp = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/poweruser/13533`, { withCredentials: true });//DEV
      const { permissions } = poweruserResp.data
      userObj.permissions = permissions;
      userObj.isPowerUser = true

    } catch (error) {
      userObj.isPowerUser = false;
    }

    setCurrentUser(userObj);
    setIsLoading(false);
  }


  // useEffect(() => {
  //   token === '' && authorizeClient();
  // }, [token]);

  useEffect(() => {

    if (token && token != "") {
      getUserInfo();
    } else {
      authorizeClient();
    }

  }, [token])


  return (

    <div>
      {/* <header className="d-flex align-items-center pb-3 mb-5 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <span className="fs-4">Learning Plans</span>
          </a>
        </header> */}
      {isLoading ? <><div className="container p-3 mt-3"><div className="row"><h6 className="text-center color-grey pt-5"><Spinner animation="border" /></h6></div></div> </> :
        <div className='pt-5'>
          {/* {currentUser && <h6 className='float-end'>{currentUser.username} {currentUser.isPowerUser && '(power user!)'}</h6>} */}
          <LearningPlans />

        </div>
      }
    </div>

  );
}

export default App;
